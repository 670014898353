<template>
  <v-dialog :value="dialog" persistent max-width="400px">
    <modify-profile-card
      @clickClose="$_onClickClose"
      @modifyProfile="$_onClickClose"
    ></modify-profile-card>
  </v-dialog>
</template>

<script>
import ModifyProfileCard from '@/components/User/Cards/ModifyProfileCard'
import { DialogMixin } from '@/components/Mixins'

const props = {
  dialog: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'ModifyProfileDialog',
  components: { ModifyProfileCard },
  props,
  mixins: [DialogMixin]
}
</script>
